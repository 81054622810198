
import Kirbyimage from '../kirbyimage.vue';

export default
{
    name: "insightcard",
    props: {
        show_date: Boolean,
        blog: Object
    },
    components: { Kirbyimage }
}



import Insightcard from "./v2/insightcard.vue";
import Swiper from "swiper";

export default {
  name: "thelatest",
  components: { Insightcard },
  data() {
    return {
      blogs: [],
    };
  },
  mounted() {
    this.$axios
      .$post(`/api/content`,
        {
          query: "site.index.template('blog')",
          select: {
            listing: "page.blocks.toListingHelper",
          },
        }
      )
      .then((resp) => {
        this.blogs = resp.result.data.sort(function (a, b) {
          return a.listing.Unix - b.listing.Unix;
        });
      })
      .finally((resp) => {
        new Swiper(".swiper-latest", {
          hashNavigation: {
            watchState: true,
          },
          loop: false,
          slideToClickedSlide: true,
          slidesPerView: "auto",
          spaceBetween: 30,
          pagination: {
            el: "#swiper-pagination-latest",
            clickable: true,
            type: "bullets",
          },
        });
      });
  },
};
